<!--
 * @Author: yanzq
 * @Date: 2021-12-22 21:41:32
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-01-08 17:53:49
 * @Description: 请填写简介
-->
<template>
  <div>
    <div class="banner flex-y-s">
      <a href="javasctipt:;" @click="backLevel" class="backBtn">返回</a>
      <a class="homeBtn" @click="backHome" href="javascript:;">首页</a>
      <div>
        <h1 style="font-size: 22px; position: relative; top: -20px">
          企业服务在线
        </h1>
        <p
          style="
            font-size: 16px;
            margin-top: 5px;
            position: relative;
            top: -20px;
          "
        >
          欢迎您！{{ this.username }}
        </p>
        <div class="company-info" style="margin-top: -10px">
          <span>{{ info.waCompanyName }}</span>
        </div>
      </div>
    </div>
    <van-form @submit="onSubmit" style="margin-top: 60px">
      <van-cell-group inset>
        <van-field
          class="flex-block"
          required
          v-model="fromValue.selectSd"
          name="selectSd"
          label="属地"
          placeholder="请选择属地信息"
          :rules="[{ required: true, message: '请选择属地信息' }]"
        >
          <template #button>
            <a @click="show = true" class="select-btn">选择</a>
          </template>
        </van-field>
        <van-field
          class="flex-block"
          required
          v-model="fromValue.selectSy"
          name="selectSy"
          label="属业"
          placeholder="请选择"
          :rules="[{ required: true, message: '请选择属业信息' }]"
        >
          <template #button>
            <a class="select-btn" @click="getHyTree">选择</a>
          </template>
        </van-field>
        <van-field
          class="flex-block"
          required
          v-model="fromValue.selectSx"
          name="password2"
          maxlength="4"
          label="属性"
          placeholder="请选择属性信息"
          :rules="[{ required: true, message: '请选择属性信息' }]"
        >
          <template #button>
            <a class="select-btn" @click="getCompanyTypeTree">选择</a>
          </template>
        </van-field>
      </van-cell-group>
      <div style="margin: 16px; display: flex; justify-content: center">
        <van-button
          round
          block
          type="info"
          native-type="submit"
          style="
            width: 46%;
            background-image: linear-gradient(90deg, #2d90fb 0%, #84bfff 100%);
            height: 36px;
          "
        >
          提交
        </van-button>
      </div>
    </van-form>

    <!-- 属地 -->
    <van-popup
      class="first-popup"
      v-model="show"
      position="bottom"
      :style="{ height: '30%' }"
    >
      <van-field
        style="margin-bottom: 8px"
        class="flex-block"
        required
        label="属性"
      ></van-field>
      <!-- <van-search v-model="searchValue" placeholder="请输入搜索关键词" @search="onSearch">
        <template #right-icon>
          <van-icon name="search" @click="onSearch" color="#008bff" style="margin-right: 5px" />
        </template>
      </van-search> -->
      <ul style="height: 250px; overflow: scroll">
        <li
          v-for="(item, index) in jdList"
          :key="index"
          @click="selectSdMethod(item)"
          :class="{ active: fromValue.selectSd == item }"
        >
          {{ item }}
        </li>
      </ul>
    </van-popup>
    <!-- 属业 -->
    <van-popup
      class="first-popup"
      v-model="show2"
      position="bottom"
      :style="{ height: '50%' }"
    >
      <van-field
        style="margin-bottom: 8px"
        class="flex-block"
        required
        label="属业"
      ></van-field>
      <van-search
        v-model="searchValue2"
        placeholder="请输入搜索关键词"
        @search="onSearch2"
      >
        <template #right-icon>
          <van-icon
            name="search"
            @click="onSearch2"
            color="#008bff"
            style="margin-right: 5px"
          />
        </template>
      </van-search>

      <ul id="sy" class="ztree" />
    </van-popup>
    <!-- 属性 -->
    <van-popup
      class="first-popup"
      v-model="show3"
      position="bottom"
      :style="{ height: '50%' }"
    >
      <van-field
        style="margin-bottom: 8px"
        class="flex-block"
        required
        label="属性"
      ></van-field>
      <van-search
        v-model="searchValue3"
        placeholder="请输入搜索关键词"
        @search="onSearch3"
      >
        <template #right-icon>
          <van-icon
            name="search"
            @click="onSearch3"
            color="#008bff"
            style="margin-right: 5px"
          />
        </template>
      </van-search>

      <ul id="sx" class="ztree" />
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import {
  getCompanyTypeTree,
  getHyTree,
  updateOneEnterpriseSupervision,
} from "../../api/home";
export default {
  name: "improve-information",

  data() {
    return {
      jdList: [
        "白杨街道",
        "河庄街道",
        "临江街道",
        "前进街道",
        "下沙街道",
        "新湾街道",
        "义蓬街道",
      ],
      info: {},
      show: false,
      show2: false, // 属业展示
      searchValue2: "", //属业 搜索值
      show3: false, // 属性展示
      searchValue3: "", //属性搜索值
      fromValue: {
        selectSd: "白杨街道",
        selectSx: "",
        selectSxUui: "",
        selectSy: "",
        selectSyUuid: "",
      },
      username: window.sessionStorage.username || "",
    };
  },

  mounted() {
    this.info = JSON.parse(this.$route.query.item);
    // this.getHyTree();
    // this.getCompanyTypeTree();
  },

  methods: {
    backLevel(e) {
      e.preventDefault();
      this.$router.go(-1);
      return false;
    },
    backHome(e) {
      e.preventDefault();
      wx.closeWindow();
      return false;
    },
    selectSdMethod(item) {
      this.fromValue.selectSd = item;
      this.show = false;
    },
    //获取属性结构树
    getCompanyTypeTree() {
      let that = this;
      that.show3 = true;
      let data = {
        strList: this.info.uuid,
        "strMap.name": this.searchValue1,
      };
      getCompanyTypeTree(data).then((res) => {
        $.fn.zTree.init(
          $("#sx"),
          {
            data: {
              key: {
                name: "text",
              },
            },
            callback: {
              onClick(event, treeId, treeNode) {
                if (!treeNode.children) {
                  that.fromValue.selectSx = treeNode.text;
                  that.fromValue.selectSxUuid = treeNode.uuid;
                  that.show3 = false;
                }
              },
            },
          },
          res.data
        );
      });
    },
    //获取属性结构树
    getHyTree() {
      let that = this;
      that.show2 = true;
      let data = {
        strList: this.info.uuid,
        "strMap.name": this.searchValue1,
      };
      getHyTree(data).then((res) => {
        $.fn.zTree.init(
          $("#sy"),
          {
            data: {
              key: {
                name: "text",
              },
            },

            callback: {
              onClick(event, treeId, treeNode) {
                if (!treeNode.children) {
                  that.fromValue.selectSy = treeNode.text;
                  that.fromValue.selectSyUuid = treeNode.uuid;
                  that.show2 = false;
                }
              },
            },
          },
          res.data
        );
      });
    },
    onSubmit() {
      let that = this;
      let data = {
        shxydm: this.info.waShxydm,
        esTerritorial: this.fromValue.selectSd,
        esIndustry: this.fromValue.selectSyUuid,
        esAttribute: this.fromValue.selectSxUuid,
      };

      updateOneEnterpriseSupervision(data)
        .then((res) => {
          if (res.success) {
            Toast({
              type: "success",
              message: "操作成功",
              onClose: () => {
                that.$router.go(-1);
              },
            });
          }
        })
        .catch((err) => {
          Toast.fail("操作失败");
        });
    },

    onSearch2() {
      var treeObj = $.fn.zTree.getZTreeObj("sy");
      var nodes = treeObj.getNodesByParamFuzzy("text", this.searchValue2, null);
      if (nodes.length > 0) {
        treeObj.selectNode(nodes[0]); //会让节点自动滚到到可视区域内
      }
    },
    onSearch3() {
      var treeObj = $.fn.zTree.getZTreeObj("sx");
      var nodes = treeObj.getNodesByParamFuzzy("text", this.searchValue2, null);
      if (nodes.length > 0) {
        treeObj.selectNode(nodes[0]); //会让节点自动滚到到可视区域内
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ztree * {
  font-size: 14px;
}
::v-deep.ztree a span:first-child {
  display: none !important;
}
.first-popup ul {
  li {
    width: 92%;
    margin: 0 auto;
    padding: 12px 0;
  }
  .active {
    background-image: linear-gradient(-29deg, #3fa8ff 0%, #1a6ee9 100%);
    border-radius: 4px;
    color: white;
  }
}
::v-deep.first-popup .flex-block .van-field__body {
  display: none !important;
}
::v-deep.first-popup .flex-block {
  width: 92%;
  margin: 0 auto;
  .van-field__label {
    margin-bottom: -5px;
  }
}
::v-deep .van-field .van-field__left-icon {
  display: none !important;
}
::v-deep .van-field__error-message {
  display: none !important;
}
.select-btn {
  margin-right: 10px;
  color: #1988f9;
  border: 1px solid #008bff;
  border-radius: 4px;
  padding: 6px;
}
.company-info {
  bottom: -48px;
  width: 108%;
  margin: auto 0;
  position: absolute;
  background: url("../../assets/images/userCenter/b1.png");
  background-size: 100% 100%;
  left: rem(-30);
  height: 140px;
  span {
    position: absolute;
    top: 51px;
    left: 65px;
    font-size: 16px;
  }
}
.van-cell {
  padding: 0;
  background: none;

  display: block !important;
}
::-webkit-input-placeholder {
  color: white !important;
}
.van-form {
  width: 95%;
  margin: auto;
}
.flex-block {
  display: block;
  padding-bottom: 0;
  ::v-deep.van-field__label {
    width: 100%;
    font-size: rem(28);
    color: #333333;
    margin-top: 10px;
    margin-bottom: 5px;
    text-indent: 10px;
    font-weight: bold;
  }
}
::v-deep .van-cell__value {
  display: flex;
  flex-wrap: wrap !important;
  align-items: center;
}
::v-deep .van-field__body {
  border-radius: 4px;
  width: 100%;
  height: rem(66);
}
::v-deep .van-form .van-field__body {
  background: rgba(95, 76, 76, 0.05);
}
::v-deep.van-cell--required::before {
  content: "";
  position: absolute;
  left: rem(0);
  top: rem(10);
  width: rem(6);
  height: rem(28);
  background-image: linear-gradient(179deg, #2d90fb 0%, #84bfff 100%);
  border-radius: 40px;
}
.banner {
  padding: rem(20);
  width: 100%;
  position: relative;
  height: rem(342);
  background: url("../../assets/images/userCenter/banner-bg3.png");
  background-size: 100%;
  color: white;
  p {
    font-size: rem(36);
    font-weight: bold;
  }
  img {
    width: rem(36);
    height: rem(36);
  }
}
</style>
